body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*修复默认margin padding左浮动40px问题*/
*{ margin:0; padding:0; }
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
#header {
  background-color: #fff;
  position: relative;
  z-index: 10;
  height: 64px;
}
#logo {
  overflow: hidden;
  padding-left: 40px;
  float: left;
  line-height: 64px;
  text-decoration: none;
  height: 64px;
}
#logo img {
  display: inline;
  vertical-align: middle;
  margin-right: 16px;
  width: 32px;
}
#logo span {
  color: #1890ff;
  outline: none;
  font-size: 14px;
  line-height: 28px;
}
.header-meta {
  padding-right: 40px;
}
.header-meta::before {
  display: table;
  content: '';
}
.header-meta::after {
  display: table;
  clear: both;
  content: '';
}
#menu {
  float: right;
  overflow: hidden;
  height: 64px;
}
#menu .ant-menu {
  line-height: 60px;
}
#menu .ant-menu-horizontal {
  border-bottom: none;
}
#menu .ant-menu-horizontal > .ant-menu-item {
  border-top: 2px solid transparent;
}
#menu .ant-menu-horizontal > .ant-menu-item:hover {
  border-top: 2px solid #1890ff;
  border-bottom: 2px solid transparent;
}
#menu .ant-menu-horizontal > .ant-menu-item-selected {
  border-top: 2px solid #1890ff;
  border-bottom: 2px solid transparent;
}
#menu .ant-menu-horizontal > .ant-menu-item-selected a {
  color: #1890ff;
}
#preview {
  padding-top: 17px;
  float: right;
  margin-left: 32px;
}
#preview button {
  border-radius: 32px;
}
#preview-button .ant-btn {
  color: #314659;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
html,
body {
  height: 100%;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 1.5;
  color: #314659;
  font-size: 14px;
  background: #fff;
  transition: background 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow-x: hidden;
}
a {
  transition: color 0.3s ease;
}
a:focus {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip: ink;
}
.home-wrapper {
  width: 100%;
  color: #697b8c;
}
.home-wrapper .ant-btn {
  min-width: 110px;
  height: 40px;
  border-radius: 20px;
  font-size: 16px;
}
.home-wrapper .ant-btn:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(24, 144, 255, 0.4);
}
svg g {
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  transform-box: fill-box;
}
.banner-wrapper {
  height: 526px;
  width: 100%;
  max-width: 1500px;
  margin: auto;
  position: relative;
}
.banner-wrapper .banner-title-wrapper {
  width: 40%;
  max-width: 480px;
  height: 245px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10%;
  margin: auto;
  z-index: 1;
}
.banner-wrapper .banner-title-wrapper > * {
  will-change: transform;
}
.banner-wrapper .banner-title-wrapper h1 {
  font-family: "Futura", "Helvetica Neue For Number", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 40px;
  margin: 12px 0;
}
.banner-wrapper .banner-title-wrapper p {
  font-size: 17px;
}
.banner-wrapper .banner-title-wrapper .button-wrapper {
  margin-top: 64px;
  line-height: 40px;
  align-items: center;
  display: flex;
}
.banner-wrapper .banner-title-wrapper .button-wrapper .github-btn {
  display: inline-block;
  height: 28px;
}
.banner-wrapper .banner-title-wrapper .button-wrapper .github-btn .gh-btn {
  height: 28px;
  border-radius: 4px;
  background: #f3f3f3;
  background: linear-gradient(to bottom, #ffffff 0%, #f3f3f3 100%);
  border: 1px solid #ebedf0;
  align-items: center;
  display: flex;
  padding: 0 12px;
  font-size: 13px;
}
.banner-wrapper .banner-title-wrapper .button-wrapper .github-btn .gh-btn:hover {
  color: #1890ff;
}
.banner-wrapper .banner-title-wrapper .button-wrapper .github-btn .gh-btn .gh-ico {
  margin-right: 8px;
}
.banner-wrapper .banner-title-wrapper .button-wrapper .github-btn .gh-count {
  height: 28px;
  line-height: 22px;
  background: #fff;
  border: 1px solid #ebedf0;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 13px;
}
.banner-wrapper .banner-title-wrapper .title-line {
  -webkit-transform: translateX(-64px);
          transform: translateX(-64px);
  -webkit-animation: bannerTitleLine 3s ease-in-out 0s infinite;
          animation: bannerTitleLine 3s ease-in-out 0s infinite;
}
.banner-wrapper .banner-image-wrapper {
  width: 45%;
  max-width: 598px;
  height: 324px;
  position: absolute;
  right: 8%;
  margin: auto;
  top: 0;
  bottom: 0;
  opacity: 0;
}
.home-banner-image {
  display: none;
}
.title-line-wrapper {
  height: 2px;
  width: 100%;
  overflow: hidden;
}
.title-line-wrapper .title-line {
  height: 100%;
  width: 64px;
  -webkit-transform: translateX(-64px);
          transform: translateX(-64px);
  background: linear-gradient(to right, rgba(24, 144, 255, 0) 0%, #1890ff 100%);
}
.home-page {
  margin: 50px auto;
}
.home-page h2 {
  text-align: center;
  font-weight: 300;
  font-size: 28px;
  color: #314659;
  letter-spacing: 0.6px;
}
.home-page h2 span {
  font-weight: 600;
}
.home-page-wrapper {
  max-width: 1280px;
  width: 100%;
  margin: auto;
  position: relative;
}
/** page1 **/
.page1 {
  height: 960px;
}
.page1-line.title-line-wrapper {
  width: 312px;
  margin: 24px auto 76px;
}
.page1-line.title-line-wrapper .title-line {
  -webkit-animation: page1TitleLine 3s ease-in-out 1.5s infinite;
          animation: page1TitleLine 3s ease-in-out 1.5s infinite;
}
.page1-bg {
  font-size: 320px;
  color: #ebedf0;
  position: absolute;
  width: 100%;
  text-align: center;
  opacity: 0.25;
  top: 0;
  -webkit-transform: translateY(960px);
          transform: translateY(960px);
}
.page1-box-wrapper {
  margin-bottom: 62px;
  display: flex;
  align-items: flex-start;
}
.page1-box-wrapper li {
  width: 33.33%;
  display: inline-block;
  will-change: transform;
}
.page1-box-wrapper li .page1-box {
  width: 194px;
  margin: auto;
  text-align: center;
  position: relative;
}
.page1-box-wrapper li .page1-box .page1-image {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  margin: 20px auto 32px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
.page1-box-wrapper li .page1-box:hover .page1-image {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.page1-box-wrapper li .page1-box h3 {
  color: #0d1a26;
  font-size: 16px;
  margin: 8px auto;
}
.page1-point-wrapper {
  position: absolute;
  width: 0;
  left: 50%;
  top: 0;
}
.page1-point-wrapper .point-0 {
  width: 4px;
  height: 4px;
}
.page1-point-wrapper .point-2,
.page1-point-wrapper .point-ring {
  width: 10px;
  height: 10px;
}
.page1-point-wrapper .point-ring {
  border-style: solid;
  border-width: 1px;
  background: transparent !important;
}
.page1-point-wrapper .point-1 {
  width: 6px;
  height: 6px;
}
.page1-point-wrapper .point-3 {
  width: 15px;
  height: 15px;
}
.page1-point-wrapper i {
  display: inline-block;
  border-radius: 100%;
  position: absolute;
  opacity: 0;
  -webkit-transform: translate(0, 30px);
          transform: translate(0, 30px);
}
/** page2 **/
.page2 {
  text-align: center;
  height: 588px;
}
.page2 .page2-content {
  will-change: transform;
}
.page2-line {
  margin: 148px auto 24px;
  width: 114px;
}
.page2-line .title-line {
  -webkit-animation: page2TitleLine 3s ease-in-out 0s infinite;
          animation: page2TitleLine 3s ease-in-out 0s infinite;
}
.page-content {
  width: 760px;
  margin: 24px auto 32px;
  line-height: 28px;
}
.home-code {
  width: 90%;
  max-width: 840px;
  border-radius: 4px;
  background: #f2f4f5;
  line-height: 28px;
  margin: 16px auto;
  color: #151e26;
  font-size: 16px;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  text-align: left;
  padding: 20px 50px;
}
.home-code span {
  color: #f5222d;
}
.home-code-comment {
  color: #697b8c !important;
}
@-webkit-keyframes bannerTitleLine {
  0%,
  25% {
    -webkit-transform: translateX(-64px);
            transform: translateX(-64px);
  }
  75%,
  100% {
    -webkit-transform: translateX(544px);
            transform: translateX(544px);
  }
}
@keyframes bannerTitleLine {
  0%,
  25% {
    -webkit-transform: translateX(-64px);
            transform: translateX(-64px);
  }
  75%,
  100% {
    -webkit-transform: translateX(544px);
            transform: translateX(544px);
  }
}
@-webkit-keyframes page1TitleLine {
  0%,
  25% {
    -webkit-transform: translateX(-64px);
            transform: translateX(-64px);
  }
  75%,
  100% {
    -webkit-transform: translateX(376px);
            transform: translateX(376px);
  }
}
@keyframes page1TitleLine {
  0%,
  25% {
    -webkit-transform: translateX(-64px);
            transform: translateX(-64px);
  }
  75%,
  100% {
    -webkit-transform: translateX(376px);
            transform: translateX(376px);
  }
}
@-webkit-keyframes page2TitleLine {
  0%,
  25% {
    -webkit-transform: translateX(-64px);
            transform: translateX(-64px);
  }
  75%,
  100% {
    -webkit-transform: translateX(178px);
            transform: translateX(178px);
  }
}
@keyframes page2TitleLine {
  0%,
  25% {
    -webkit-transform: translateX(-64px);
            transform: translateX(-64px);
  }
  75%,
  100% {
    -webkit-transform: translateX(178px);
            transform: translateX(178px);
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
footer.dark {
  background-color: #000;
  color: rgba(255, 255, 255, 0.65);
}
footer.dark a {
  color: #fff;
}
footer.dark h2 {
  color: #ffffff;
}
footer.dark h2 > span {
  color: #ffffff;
}
footer.dark .bottom-bar {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  overflow: hidden;
}
footer {
  border-top: 1px solid #e5e7eb;
  clear: both;
  font-size: 12px;
  background: #fff;
  position: relative;
  z-index: 100;
  color: #314659;
  box-shadow: 0 1000px 0 1000px #fff;
}
footer .ant-row {
  text-align: center;
}
footer .ant-row .footer-center {
  display: inline-block;
  text-align: left;
}
footer .ant-row .footer-center > h2 {
  font-size: 14px;
  margin: 0 auto 24px;
  font-weight: 500;
  position: relative;
}
footer .ant-row .footer-center > h2 > .anticon {
  font-size: 16px;
  position: absolute;
  left: -22px;
  top: 3px;
  color: #aaa;
}
footer .ant-row .footer-center > div {
  margin: 12px 0;
}
footer .footer-wrap {
  position: relative;
  padding: 86px 114px 70px 114px;
}
footer .bottom-bar {
  border-top: 1px solid #e5e7eb;
  text-align: right;
  padding: 20px 114px;
  margin: 0;
  line-height: 24px;
}
footer .bottom-bar a {
  color: rgba(255, 255, 255, 0.65);
}
footer .bottom-bar a:hover {
  color: #fff;
}
footer .bottom-bar .translate-button {
  text-align: left;
  width: 200px;
  margin: 0 auto;
}
footer .footer-logo {
  position: relative;
  top: -2px;
}
footer .footer-flag {
  position: relative;
  top: -4px;
  margin-right: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.nav-phone-icon {
  display: none;
  position: absolute;
  right: 30px;
  top: 25px;
  z-index: 1;
  width: 16px;
  height: 22px;
  cursor: pointer;
}
@media only screen and (min-width: 1440px) and (max-width: 1599px) {
  .main-wrapper > .ant-row > .ant-col-xl-5 {
    width: 274px;
  }
  #header .ant-row .ant-col-xl-5 {
    width: 274px;
  }
}
@media only screen and (max-width: 1300px) {
  #search-box {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  #logo {
    padding: 0 40px;
  }
  .banner-wrapper .banner-title-wrapper h1 {
    font-size: 36px;
  }
  .banner-wrapper .banner-title-wrapper p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 992px) {
  .code-boxes-col-2-1,
  .code-boxes-col-1-1 {
    float: none;
    width: 100%;
  }
  .preview-image-boxes {
    margin: 0 !important;
    float: none;
    width: 100%;
  }
  .preview-image-box {
    padding-left: 0;
    margin: 10px 0;
  }
  .banner-entry {
    position: relative;
    top: 30px;
    left: 0;
    text-align: center;
  }
  .image-wrapper {
    display: none;
  }
  .banner-wrapper {
    background-position: 40%;
  }
  .content-wrapper .text-wrapper {
    float: none;
    text-align: center;
    left: 0;
    width: 100%;
    padding: 0;
  }
  .content-wrapper .text-wrapper > p {
    max-width: 100% !important;
    padding: 0 40px;
  }
  .content-wrapper.page {
    min-height: 300px;
    height: 300px;
  }
  .banner-text-wrapper {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    text-align: center;
  }
  .banner-text-wrapper .start-button {
    text-align: center;
  }
  .banner-text-wrapper .start-button > a {
    margin: 0 4px;
  }
  .banner-text-wrapper .github-btn {
    text-align: center;
    float: none;
    display: inline-block;
  }
  .banner-text-wrapper .line {
    display: none;
  }
  button.lang {
    display: block;
    margin: 29px auto 16px;
    color: #314659;
    border-color: #314659;
  }
  div.version {
    display: block;
    margin: 29px auto 16px;
  }
  div.version > .ant-select-selection {
    color: #314659;
  }
  div.version > .ant-select-selection:not(:hover) {
    border-color: #314659;
  }
  .popover-menu {
    width: 300px;
  }
  .popover-menu button.lang {
    margin: 16px auto;
    float: none;
  }
  .popover-menu div.version {
    margin: 32px auto 16px;
    float: none;
  }
  .popover-menu .ant-popover-inner {
    overflow: hidden;
  }
  .popover-menu .ant-popover-inner-content {
    padding: 0;
  }
  .toc {
    display: none;
  }
  .nav-phone-icon {
    display: block;
  }
  .nav-phone-icon:before {
    content: "";
    display: block;
    border-radius: 2px;
    width: 16px;
    height: 2px;
    background: #777;
    box-shadow: 0 6px 0 0 #777, 0 12px 0 0 #777;
    position: absolute;
  }
  .main {
    height: calc(100% - 86px);
  }
  .aside-container {
    float: none;
    width: auto;
    padding-bottom: 30px;
    border-right: 0;
    margin-bottom: 30px;
  }
  .main-container {
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 0;
  }
  .main-container > .markdown > * {
    width: 100% !important;
  }
  .main-wrapper {
    width: 100%;
    border-radius: 0;
    margin: 0;
  }
  #footer {
    text-align: center;
  }
  #footer .footer-wrap {
    padding: 40px;
  }
  #footer .footer-center {
    text-align: center;
  }
  #footer h2 {
    margin-top: 16px;
  }
  #footer .bottom-bar {
    text-align: center;
  }
  #footer .bottom-bar .translate-button {
    width: auto;
    text-align: center;
    margin-bottom: 16px;
  }
  .prev-next-nav {
    margin-left: 16px;
    width: calc(100% - 32px);
  }
  .drawer .ant-menu-inline .ant-menu-item:after,
  .drawer .ant-menu-vertical .ant-menu-item:after {
    left: 0;
    right: auto;
  }
}
@media only screen and (max-width: 768px) {
  #logo {
    padding: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    float: none;
    width: 200px;
  }
  .header-meta {
    padding-right: 80px;
  }
  .home-banner-image {
    display: block;
  }
  .home-banner-anim {
    display: none;
  }
  .banner-wrapper {
    width: 80%;
    height: calc(100vh - 64px);
    overflow: hidden;
  }
  .banner-wrapper .banner-title-wrapper,
  .banner-wrapper .banner-image-wrapper {
    display: block;
    position: initial;
    width: 100%;
    height: auto;
  }
  .banner-wrapper .banner-title-wrapper {
    text-align: center;
    max-width: 480px;
  }
  .banner-wrapper .banner-title-wrapper .button-wrapper {
    text-align: center;
    display: block;
    margin-top: 5vh;
  }
  .banner-wrapper .banner-title-wrapper .button-wrapper a {
    display: inline-block;
  }
  .banner-wrapper .banner-title-wrapper .button-wrapper .github-btn {
    display: flex;
    margin: 20px auto;
    justify-content: center;
  }
  .banner-wrapper .banner-image-wrapper {
    margin: 10vh auto 5vh;
  }
  .home-page {
    width: 90%;
  }
  .home-code,
  .page-content {
    width: 100%;
  }
  #footer .footer-wrap {
    padding: 0;
  }
}
@media only screen and (max-width: 480px) {
  .page1 {
    height: 1600px;
  }
  .page2 {
    height: 628px;
  }
  .page1-box-wrapper {
    display: block;
  }
  .page1-box-wrapper li {
    width: 80%;
    display: block;
    margin: 0 auto 100px;
  }
  .page1-box-wrapper li .page1-box {
    width: 100%;
  }
  .banner-wrapper .banner-title-wrapper h1 {
    font-size: 28px;
  }
  .banner-wrapper .banner-title-wrapper p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 320px) {
  .home-page h2 {
    font-size: 24px;
  }
  .page2 {
    height: 648px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

